import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./Sync.css";
import MessageSync from "./MessageSync";
import SyncStatus from "./SyncStatus";

const Sync = () => {
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0); // Almacena el tiempo transcurrido en segundos
  const [showMessage, setShowMessage] = useState(false);
  const [error, setError] = useState(null);
  const intervalRef = useRef(null);

  // Convierte los segundos a un formato más legible (minutos:segundos)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Función para manejar la sincronización completa
  const handleFullSync = async () => {
    if (loading) return; // Previene múltiples clics mientras ya está en proceso

    setLoading(true);
    setCounter(0);
    setShowMessage(false);
    setError(null);

    // Iniciar el contador
    intervalRef.current = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);

    try {
      const url =
        "https://h3acfowa7pbaqtrklgl6ehgojm0cxuhn.lambda-url.us-east-1.on.aws/";
      const payload = { key: "value" };

      const response = await axios.post(url, payload);
      console.log("Respuesta de Lambda:", response.data);
      setShowMessage(true);
    } catch (error) {
      console.error("Error al ejecutar la Lambda:", error.message);
      setError("Hubo un problema durante la sincronización. Inténtalo de nuevo.");
    } finally {
      setLoading(false);
      clearInterval(intervalRef.current); // Detiene el contador una vez finalizado
    }
  };

  useEffect(() => {
    // Limpiar el intervalo si el componente se desmonta
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <div className="contenedor-document">
      <SyncStatus />

      <div className="contenedor-botones">
        <button
          type="button"
          className="btn-submit"
          onClick={handleFullSync}
          disabled={loading}
        >
          {loading ? `Sincronizando... ${formatTime(counter)}` : "Sincronizar productos"}
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}
      {showMessage && <MessageSync onClose={() => setShowMessage(false)} />}
    </div>
  );
};

export default Sync;
