import React, { useEffect, useState } from "react";

const ReadCategories = ({ onCategoryChange }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch("https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/readcategory")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setCategories(data);
        } else {
          console.error("Invalid data structure");
        }
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

const handleChange = (e) => {
  const selectedCategory = categories.find(
    (cat) => String(cat.category_id) === String(e.target.value)
  );
  if (selectedCategory) {
    onCategoryChange({
      name: selectedCategory.category_name,
      id: selectedCategory.category_id,
    });
  }
};


  return (
    <div className="form-group">
      <label htmlFor="category">Categoría:</label>
      <select
        id="category"
        onChange={handleChange}
        className="form-control"
      >
        <option value="">Selecciona una categoría</option>
        {categories.map((category) => (
          <option key={category.category_id} value={category.category_id}>
            {category.category_name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ReadCategories;
