import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";

const ReadProduct = ({ categoryId, onProductChange }) => {
  const [products, setProducts] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Estado para la búsqueda
  const [loading, setLoading] = useState(false); // Estado de carga
  const [error, setError] = useState(null); // Estado de error

  useEffect(() => {
    if (categoryId) {
      setLoading(true);
      setError(null); // Reseteamos el error al hacer una nueva llamada
      // Llamada a la API con categoryId y searchQuery
      fetch(`https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/readproduct?categoryId=${categoryId}&searchQuery=${searchQuery}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('API request failed with status: ' + response.status);
          }
          return response.json();
        })
        .then((data) => {
          if (!data || !Array.isArray(data.products)) {
            console.error('No se encontraron productos en la respuesta de la API');
            setProducts([]);
            setOptions([]);
            return;
          }

          const filteredProducts = data.products.filter((product) => {
            // Verificamos si el categoryId está en las categorías del producto
            const isCategoryMatch = product.categories.some(category => category.category_id === parseInt(categoryId));

            return isCategoryMatch && (
              product.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              product.product_reference.toLowerCase().includes(searchQuery.toLowerCase())
            );
          });

          // Formateamos los productos para usar en react-select
          const productOptions = filteredProducts.map(product => ({
            value: product.product_id, // Usamos el product_id como valor
            label: `${product.product_name} (${product.product_reference})`, // Nombre y referencia como label
          }));

          setProducts(filteredProducts);
          setOptions(productOptions);
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
          setError("No se pudieron cargar los productos.");
          setProducts([]);
          setOptions([]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setProducts([]);
      setOptions([]);
    }
  }, [categoryId, searchQuery]);

  const handleChange = useCallback((selectedOption) => {
    if (selectedOption) {
      // Solo intentamos acceder al id si selectedOption no es null
      const selectedProduct = products.find(prod => prod.product_id === selectedOption.value);
      if (selectedProduct) {
        onProductChange({ name: selectedProduct.product_name, id: selectedProduct.product_id });
      }
    } else {
      // Si no se selecciona un producto, pasamos null al componente padre
      onProductChange(null);
    }
  }, [products, onProductChange]);
  

  return (
    <div className="form-group">
      <label htmlFor="product">Productos:</label>
      {/* Mostrar error si existe */}
      {error && <div className="error-message">{error}</div>}
      
      {/* Select con los productos */}
      <Select
        id="product"
        options={options}
        onChange={handleChange}
        placeholder={loading ? "Cargando..." : "Selecciona un producto o busca por nombre o referencia"}
        isClearable
        isSearchable
        className="form-control"
        onInputChange={(inputValue) => setSearchQuery(inputValue)} 
      />
    </div>
  );
};

export default ReadProduct;
