import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./Sync.css";

const SyncStatus = () => {
  const [loading, setLoading] = useState(false);
  const [syncStatus] = useState(null);
  const [counter, setCounter] = useState(0); // Almacena el tiempo transcurrido en segundos
  const [error, setError] = useState(null);
  const intervalRef = useRef(null);

  // Convierte los segundos a un formato más legible (minutos:segundos)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Función para iniciar el contador
  const startCounter = () => {
    setCounter(0); // Reinicia el contador
    intervalRef.current = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1); // Incrementa cada segundo
    }, 1000);
  };

  // Función para detener el contador
  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  // Función para consultar el estado de sincronización
  const checkSyncStatus = async () => {
    if (loading) return; // Evita múltiples solicitudes al mismo tiempo

    setLoading(true); // Activa el estado de carga
    setError(null); // Reinicia los errores
    startCounter(); // Inicia el contador

    try {
      const response = await axios.post("https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/deleteproduct");
      const data = response.data;  // Axios ya maneja JSON automáticamente
    
      if (response.status === 200 && data.message) {
        alert(data.message);
      } else {
        alert("Hubo un error al eliminar los productos.");
      }
    } catch (error) {
      console.error("Error al eliminar productos:", error);
      alert("Error al intentar eliminar los productos.");
    }
    
  };

  // Función para manejar la eliminación de productos
  const handleDeleteProducts = async () => {
    const confirmDelete = window.confirm("¿Estás seguro de borrar estos productos?");
    if (confirmDelete) {
      setLoading(true);
      setError(null);
  
      try {
        // Enviar solicitud POST con cuerpo vacío y headers explícitos
        const response = await axios.post(
          "https://1e01glq9n4.execute-api.us-east-1.amazonaws.com/deleteproduct",
          {}, 
          {
            headers: { "Content-Type": "application/json" },
            responseType: 'text'
          }
        );
  
        console.log("Respuesta recibida:", response);
        
        // Intentar analizar la respuesta como JSON
        const responseData = JSON.parse(response.data);
        
        if (response.status === 200 && responseData.message) {
          alert(responseData.message);
        } else {
          alert("Hubo un error inesperado.");
        }
      } catch (error) {
        console.error("Error al eliminar productos:", error);
        setError("Error al intentar eliminar los productos.");
        alert("Error al intentar eliminar los productos.");
      } finally {
        setLoading(false);
      }
    }
  };
  

  useEffect(() => {
    return () => {
      stopCounter();
    };
  }, []);

  return (
    <div className="sync-status">
      <div className="frist-group">
      {error && <div className="error-message">{error}</div>}




      {/* Botón para verificar estado de sincronización */}
      <button
        type="button"
        className="btn-check-status"
        onClick={checkSyncStatus}
        disabled={loading}
      >
        {loading ? `Verificando... ${formatTime(counter)}` : "Verificar estado"}
      </button>

      <div className="boton-delete">
              {/* Botón para borrar productos */}
      <button
        type="button"
        onClick={handleDeleteProducts}
        className="btn-danger"
        disabled={loading}
        
      >
        Borrar productos
      </button>

      </div>
      </div>
<div>
      {/* Mostrar estado de sincronización */}
      {syncStatus === 1 ? (
        <button type="button" className="btn-sync-success">
          Productos sincronizados
        </button>
      ) : syncStatus === 0 ? (
        <button type="button" className="btn-sync-warning">
          No está sincronizado
        </button>
      ) : null}

</div>



    </div>
  );
};

export default SyncStatus;
